// import Container from './index.vue'
import Vue from 'vue'

export default async () => {
  const Container = await import(
    /* webpackChunkName: "cod-verify-free-popup-module" */ './index.vue'
  ).catch(() => '')
  if (!Container?.default) return
  const instance = new Vue(Container?.default)
  instance.$mount()
  document.body.appendChild(instance.$el)
  instance.$el.setAttribute('class', 'cod-verify-free-popup-container')

  instance.unmount = () => {
    instance.$destroy()
    document.body.removeChild(instance.$el)
  }

  return instance
}
