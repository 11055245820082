import { ref } from 'vue'

const store = ref({})

const defaultState = {
  order: {},
  goBackTipShow: false,
  abtInfoObj: {},
  quantitySwitchValue: false,
  retentionLowPrice: '',
  goodsItems: [],
  tspList: [],
  serviceTypeArr: [],
  couponData: {},
  retentionLimit: 100,
  errInfo: {},
  errTip: '',
  langText: {},
  showPopup: false,
  countdown: 5,
  timer: null,
  needCountdown: false,
  isLoading: false,
  freeVerifyErr: '',
  goVerifyTipShow: false,
}

const useStore = () => {
  const initStore = () => {
    store.value = { ...defaultState }
  }

  return {
    store,
    initStore
  }
}

export default useStore
