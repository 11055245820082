/**
 * 是否是关联订单号
 *
 * @export
 * @param {string} [billno='']
 * @return {boolean}
 */
export function isRelationBillno(billno = '') {
  if (!billno) return false
  return billno.startsWith ? billno.startsWith('U') : billno.indexOf('U') === 0
}
