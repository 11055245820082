export default {
  methods: {
    setBodyScroll() {
      // 记录打开弹窗之前的滚动位置
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop
      document.body.classList.add('body-fixed__order')
      document.body.style.top = `-${scrollTop}px`
      // 将滚动位置存储，以便模态关闭时可以恢复
      document.body.setAttribute('data-order-scroll-y', scrollTop)
    },
    restoreBodyScroll() {
      // 从之前存储的自定义属性读取滚动位置
      var scrollTop = document.body.getAttribute('data-order-scroll-y')
      // 还原 body 的样式属性
      document.body.style.top = ''
      document.body.classList.remove('body-fixed__order')
      // 页面滚动到之前的位置
      window.scrollTo(0, scrollTop)
    }
  }
}
