import schttp from 'public/src/services/schttp'
import useStore from './store'
import createApp from './createApp'

const { store, initStore } = useStore()

class CodVerifyFreePopup {
  constructor() {
    this.instance = null
  }

  async getData(order) {
    try {
      const result = await schttp({
        url: '/api/pay/cod/pageInfo/get',
        method: 'GET',
        params: {
          billno: order?.billno
        }
      })
      if (result?.code == 0) {
        const { info = {}, language, abtInfoObj, retentionLimit } = result || {}
        store.value.order = info?.order || {}
        store.value.langText = language || {}
        store.value.abtInfoObj = abtInfoObj || {}
        store.value.retentionLimit = retentionLimit || 100
      }
    } catch (e) {
      console.error(e)
    }
  }

  async createInstance() {
    if (this.instance) return
    this.instance = await createApp()
  }

  async initPopup(config) {
    initStore()
    const { order, needCountdown } = config
    // store.value.order = order
    store.value.needCountdown = needCountdown
    await this.getData(order)
    await this.createInstance()
  }

  /**
   * Description placeholder
   *
   * @async
   * @param {{ needCountdown: boolean, order: object }} config
   * @returns {*}
   */
  async createPopup(config) {
    await this.initPopup(config)
    if (this.instance) {
      this.instance.show()
      return
    }
  }
}

export default new CodVerifyFreePopup()
